// AuthProvider.js

import { useEffect, useState } from 'react';
import { msalInstance } from './msalConfig';
import AuthContext from './authContext';

const AuthProvider = ({ children }) => {
  const [account, setAccount] = useState(null);

  useEffect(() => {
    const handleRedirect = async () => {
      try {
        const authResponse = await msalInstance.handleRedirectPromise();
        if (authResponse) {
          // Authentication successful
          const accounts = msalInstance.getAllAccounts();
          if (accounts.length > 0) {
            setAccount(accounts[0]);
          }
        }
      } catch (error) {
        console.error('MSAL handleRedirectPromise error:', error);
      }
    };

    handleRedirect();
  }, []);

  const login = async () => {
    await msalInstance.loginRedirect();
  };

  const logout = () => {
    msalInstance.logout();
  };

  return (
    <AuthContext.Provider value={{ account, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
