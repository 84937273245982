import '../styles/globals.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import AuthProvider  from '../components/AuthProvider';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from '../components/msalConfig';
const msalInstance = new PublicClientApplication(msalConfig);

function MyApp({ Component, pageProps }) {
  return (
    <MsalProvider instance={msalInstance}>
      <Component {...pageProps} />
      </MsalProvider>
    
  );
}

export default MyApp